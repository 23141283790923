import * as actionTypes from '../action-types';
import scorecardKey from './keys';

const initialState = {};

const scorecardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SCORECARD_LOAD_REQUEST: {
      const { elementId, year, quarter } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: true,
          error: undefined,
        },
      };
    }
    case actionTypes.SCORECARD_LOAD_SUCCESS: {
      const { elementId, year, quarter, scorecard } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard,
          isLoading: false,
          lastUpdatedAt: new Date(),
        },
      };
    }
    case actionTypes.SCORECARD_LOAD_FAILURE: {
      const { error, elementId, year, quarter } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard: undefined,
          isLoading: false,
          error,
        },
      };
    }
    case actionTypes.OVERRIDE_VALUE_SET_SUCCESS: {
      const { elementId, year, quarter, scorecard } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard,
          lastUpdatedAt: new Date(),
        },
      };
    }
    case actionTypes.MANUAL_INDICATOR_VALUES_SET_SUCCESS: {
      const { elementId, year, quarter, scorecard } = action.payload;
      const key = scorecardKey(elementId, year, quarter);
      return {
        ...state,
        [key]: {
          ...state[key],
          scorecard,
          lastUpdatedAt: new Date(),
        },
      };
    }
    default:
      return state;
  }
};

export default scorecardReducer;

import { Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell } from '@material-ui/core';
import FormDialog from '../../../../../components/FormDialog';
import OkayCancelDialog from '../../../../../components/OkayCancelDialog';
import { ControlSelect } from '../../../../../form/ControlSelect';
import { ControlTextField } from '../../../../../form/ControlTextField';
import { WrappedInput } from '../WrappedInput';
import { useImpactProbabilityAssessment } from './hooks/useImpactProbabilityAssessment';
import { countDecimals } from '../../../../../utils/count-decimals';
import { useMemo } from 'react';

const ONE_MILLION = 1000000;

interface Props {
  formState: 'edit' | 'add' | 'view' | 'none';
  editRow: number;
  watchOpportunityOrThreat: string;
  onCancel: () => void;
  onSave: () => Promise<void>;
}

const CAD_DOLLAR = new Intl.NumberFormat('en-CA', {
  style: 'currency',
  currency: 'CAD',
});

const ImportProbabilityAssessmentDialog = ({
  formState,
  editRow,
  watchOpportunityOrThreat,
  onCancel,
  onSave,
}: Props) => {
  const {
    control,
    handleSubmit,
    errors,
    confirming,
    confirmCancel,
    onConfirmCancel,
    onCancelConfirm,
    impactAssessmentCosts,
    impactCategoryCosts,
    YEARS,
    PROBABILITY_OPTIONS,
    IMPACT_SCALE,
    FORCED_OUTAGE_RATES,
  } = useImpactProbabilityAssessment({
    formState,
    editRow,
    watchOpportunityOrThreat,
    onCancel,
    onSave,
  });

  const isReadOnly = useMemo(() => formState === 'view', [formState]);

  return (
    <FormDialog
      title="Add Impact and Probability Assessment"
      onSave={() => {
        handleSubmit();
      }}
      onCancel={isReadOnly ? onConfirmCancel : confirmCancel}
      hideSave={isReadOnly}
    >
      <Grid
        container
        spacing={2}
        style={{
          minWidth: '1356px',
        }}
      >
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlSelect
              control={control}
              name="year"
              label="Year"
              options={YEARS}
              required={true}
              multiple
              readOnly={formState === 'edit' || isReadOnly}
              error={!!errors.year}
            />
          </WrappedInput>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlSelect
              control={control}
              name="probability"
              label="Probability"
              options={PROBABILITY_OPTIONS}
              required={true}
              disabled={isReadOnly}
              data-testid="probability-dropdown"
              error={!!errors.probability}
            />
          </WrappedInput>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <WrappedInput>
            <ControlTextField
              control={control}
              type="number"
              name="probabilityRate"
              label="Probability Range"
              placeholder="Probability Range"
              error={!!errors.probabilityRate}
              disabled={isReadOnly}
              required={true}
            />
          </WrappedInput>
        </Grid>
        <Grid
          item
          xs={10}
        >
          <Typography variant="h6">Safety Impact Assessment</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Impact Category</TableCell>
                  <TableCell>Impact Value</TableCell>
                  <TableCell>Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableRow>
                <TableCell>Environmental Safety</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlSelect
                      control={control}
                      name="environmentalSafety"
                      label="Select option"
                      options={IMPACT_SCALE}
                      required={true}
                      additionalRules={{ min: 1 }}
                      error={!!errors.environmentalSafety}
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
                <TableCell>{CAD_DOLLAR.format(impactAssessmentCosts.environmentalSafetyCost / ONE_MILLION)}M</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Industrial Safety</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlSelect
                      control={control}
                      name="industrialSafety"
                      label="Select option"
                      options={IMPACT_SCALE}
                      required={true}
                      additionalRules={{ min: 1 }}
                      error={!!errors.industrialSafety}
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
                <TableCell>{CAD_DOLLAR.format(impactAssessmentCosts.industrialSafetyCost / ONE_MILLION)}M</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Radiological Safety</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlSelect
                      control={control}
                      name="radiologicalSafety"
                      label="Select option"
                      options={IMPACT_SCALE}
                      required={true}
                      additionalRules={{ min: 1 }}
                      error={!!errors.radiologicalSafety}
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
                <TableCell>{CAD_DOLLAR.format(impactAssessmentCosts.radiologicalSafetyCost / ONE_MILLION)}M</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reactor Safety</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlSelect
                      control={control}
                      name="reactorSafety"
                      label="Select option"
                      options={IMPACT_SCALE}
                      required={true}
                      additionalRules={{ min: 1 }}
                      error={!!errors.reactorSafety}
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
                <TableCell>{CAD_DOLLAR.format(impactAssessmentCosts.reactorSafetyCost / ONE_MILLION)}M</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reputational Impact</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlSelect
                      control={control}
                      name="reputationalImpact"
                      label="Select option"
                      options={IMPACT_SCALE}
                      required={true}
                      additionalRules={{ min: 1 }}
                      error={!!errors.reputationalImpact}
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
                <TableCell>{CAD_DOLLAR.format(impactAssessmentCosts.reputationalImpactCost / ONE_MILLION)}M</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Regulatory Compliance</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlSelect
                      control={control}
                      name="regulatoryCompliance"
                      label="Select option"
                      options={IMPACT_SCALE}
                      required={true}
                      additionalRules={{ min: 1 }}
                      error={!!errors.regulatoryCompliance}
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
                <TableCell>
                  {CAD_DOLLAR.format(impactAssessmentCosts.regulatoryComplianceCost / ONE_MILLION)}M
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Safety Impact</TableCell>
                <TableCell></TableCell>
                <TableCell>{CAD_DOLLAR.format(impactAssessmentCosts.totalSafetyImpact / ONE_MILLION)}M</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          item
          xs={10}
        >
          <Typography variant="h6">Production/Commercial Impact(s)</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Impact Category</TableCell>
                  <TableCell width={'50%'}>Impact Value</TableCell>
                </TableRow>
              </TableHead>
              <TableRow>
                <TableCell># of days forced outage for Production Impact</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlTextField
                      name="daysForcedOutageForProductionImpact"
                      control={control}
                      error={!!errors.daysForcedOutageForProductionImpact}
                      required={true}
                      type="number"
                      placeholder="Add value"
                      validate={value => value >= 0 && countDecimals(value) < 3}
                      helperText="Enter a non-negative number with up to two decimal places"
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell># of units for Production Impact</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlTextField
                      name="unitsForProductionImpact"
                      control={control}
                      error={!!errors.unitsForProductionImpact}
                      required={true}
                      type="number"
                      validate={value => 0 <= value && value <= 8 && Number.isInteger(+value)}
                      placeholder="Required"
                      helperText="Enter an integer between 0-8"
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
              </TableRow>
              <TableRow>
                {/* On Multiple Years calculate Multiple Values */}
                <TableCell>Forced Outage Rate/day/unit</TableCell>
                <TableCell>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                            <TableCell key={year}>{year}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableRow>
                        {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                          <TableCell key={year}>{CAD_DOLLAR.format(FORCED_OUTAGE_RATES[year])}M</TableCell>
                        ))}
                      </TableRow>
                    </Table>
                  </TableContainer>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Forced Outage Impact</TableCell>
                <TableCell>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                            <TableCell key={year}>{year}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableRow>
                        {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                          <TableCell key={year}>
                            {CAD_DOLLAR.format(impactCategoryCosts.totalForcedOutageImpacts[year])}M
                          </TableCell>
                        ))}
                      </TableRow>
                    </Table>
                  </TableContainer>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Other Commercial Impact for all affected units ($M)</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlTextField
                      name="commercialImpactForAllAffectedUnits"
                      control={control}
                      error={!!errors.commercialImpactForAllAffectedUnits}
                      required={true}
                      type="number"
                      placeholder="Required"
                      validate={value => value >= 0 && countDecimals(value) < 3}
                      helperText="Enter a non-negative number with up to two decimal places"
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Commercial Impact </TableCell>
                <TableCell>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                            <TableCell key={year}>{year}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableRow>
                        {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                          <TableCell key={year}>
                            {CAD_DOLLAR.format(impactCategoryCosts.totalCommercialImpacts[year])}M
                          </TableCell>
                        ))}
                      </TableRow>
                    </Table>
                  </TableContainer>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Safety plus Total Commercial Impact </TableCell>
                <TableCell>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                            <TableCell key={year}>{year}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableRow>
                        {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                          <TableCell key={year}>
                            {CAD_DOLLAR.format(impactCategoryCosts.totalSafetyCommercialImpacts[year])}M
                          </TableCell>
                        ))}
                      </TableRow>
                    </Table>
                  </TableContainer>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Net Impact</TableCell>
                <TableCell>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                            <TableCell key={year}>{year}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableRow>
                        {Object.keys(FORCED_OUTAGE_RATES).map(year => (
                          <TableCell key={year}>{CAD_DOLLAR.format(impactCategoryCosts.netImpacts[year])}M</TableCell>
                        ))}
                      </TableRow>
                    </Table>
                  </TableContainer>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Worst Case Total Commercial Impact ($M)</TableCell>
                <TableCell>
                  <WrappedInput>
                    <ControlTextField
                      name="worstCaseTotalCommercialImpact"
                      control={control}
                      error={!!errors.worstCaseTotalCommercialImpact}
                      required={true}
                      type="number"
                      placeholder="Required"
                      validate={value => value >= 0 && countDecimals(value) < 3}
                      helperText="Enter a non-negative number with up to two decimal places"
                      disabled={isReadOnly}
                    />
                  </WrappedInput>
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {confirming && (
        <OkayCancelDialog
          title="Continue Without Saving?"
          okayLabel="Continue"
          onOkay={onConfirmCancel}
          onCancel={onCancelConfirm}
        >
          If you choose to continue, you will lose the data you have entered.
        </OkayCancelDialog>
      )}
    </FormDialog>
  );
};

export default ImportProbabilityAssessmentDialog;
